import store from '@/store'
import { iRestrictionsKeys, restrictions } from '@/types/common/restrictions'

const Nothing = () => {
  // Nothing to do
}

const can = (restrictionName: iRestrictionsKeys): boolean => {
  const user = store.state.user
  if (!user) return false

  const restriction = restrictions[restrictionName]
  if (!restriction) return false

  return restriction.allowedRoles.includes(user.role)
}

const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export {
  Nothing,
  isMobile,
  can
}
