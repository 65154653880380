import { reactive } from 'vue'
import { ElNotification } from 'element-plus'
import { AxiosError } from 'axios'
import store from '@/store'

export type iDataRequest = {
  pending: boolean
  error: boolean
  code?: number
  success: boolean
  errorMessage: string
}

type iDataRequestOptions = {
  hideErrorMessages?: boolean
}

const useDataRequest = (useOptions: iDataRequestOptions = {}) => {
  type iFailOptions = {
    message?: string
    code: number
  }

  const state = reactive<iDataRequest>({
    pending: false,
    error: false,
    success: false,
    code: undefined,
    errorMessage: ''
  })

  return {
    state,

    start () {
      state.pending = true
      state.success = false
      state.errorMessage = ''
      state.code = undefined
      state.error = false
    },
    end () {
      state.pending = false
    },
    success () {
      state.pending = false
      state.code = 200
      state.success = true
    },
    fail (options: iFailOptions = {} as iFailOptions) {
      state.pending = false
      state.error = true
      state.code = options.code
      state.errorMessage = options.message || ''

      if (options.message && !useOptions.hideErrorMessages) {
        ElNotification({
          title: 'Ошибка',
          message: options.message,
          type: 'error'
        })
      }
    },
    async tryRequest (callback: () => void): Promise<void> {
      try {
        this.start()
        await callback()
        this.success()
      } catch (e: unknown) {
        /*
         * @todo: весь этот блок по идее надо куда то на более низкий уровень переставить
         *   оставив здесь только смену состояний, чтобы обработка была не только у форм
         */
        if (e instanceof AxiosError && e.response) {
          if (e.response.status >= 500) {
            this.fail({ message: 'Серверная ошибка', code: e.response.status })
          } else {
            switch (e.response.status) {
              case 401:
                store.logout()
                this.end()
                break
              case 403:
                this.fail({ message: 'Действие запрещено', code: 403 })
                break
              case 404:
                this.fail({ message: 'Объект не найден', code: 404 })
                break
              default:
                this.fail({ message: 'Неправильные данные', code: e.response.status })
            }
          }
        } else {
          throw e
        }
      }
    }
  }
}

export default useDataRequest
