<script setup lang="ts">

import { reactive, ref } from 'vue'
import { FormInstance, FormRules } from 'element-plus'
import useFormRequest from '@/composables/useFormRequest'
import { iRegisterForm } from '@/types/common/requests'
import { register } from '@/store/actions/auth'
import { UserRoleLabels } from '@/helpers/labels'
import VueTurnstile from 'vue-turnstile'

interface iRegisterFormState extends iRegisterForm {
  confirmPassword: string
}

const emit = defineEmits(['create'])

const formRef = ref<FormInstance>()
const formRequest = useFormRequest<iRegisterFormState>()

const turnstileSiteKey = process.env.VUE_APP_CLOUDFLARE_SITE_KEY

const form = reactive<iRegisterFormState>({
  role: 'partner',
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  captcha: ''
})

const rules = reactive<FormRules<iRegisterFormState>>({
  name: [
    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
  ],
  email: [
    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
    { type: 'email', message: 'Некорректный e-mail', trigger: 'blur' }
  ],
  password: [
    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
  ],
  confirmPassword: [
    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
    {
      validator: (rule, value, callback) => {
        if (value !== form.password) {
          callback(new Error('Пароли не совпадают'))
        } else {
          callback()
        }
      }
    }
  ]
})

const handleSubmit = async () => {
  await formRef.value?.validate(valid => {
    if (valid) {
      formRequest.tryRequest(async () => {
        await register(form)
        emit('create', form.email)
      })
    }
  })
}
</script>

<template>
  <el-container>
    <el-main>
      <el-card class="card">
        <template #header>Регистрация</template>
        <el-form
          :model="form"
          :rules="rules"
          ref="formRef"
          status-icon
          @submit.prevent="handleSubmit"
          v-loading="formRequest.state.pending"
        >

          <el-form-item prop="role" :error="formRequest.state.errors.role">
            <el-select v-model="form.role" default-first-option>
              <el-option :label="UserRoleLabels['partner']" value="partner" />
              <el-option :label="UserRoleLabels['client']" value="client" />
            </el-select>
          </el-form-item>

          <el-form-item prop="name" :error="formRequest.state.errors.name">
            <el-input v-model="form.name" placeholder="ФИО" type="text" autocomplete="name"/>
          </el-form-item>

          <el-form-item prop="email" :error="formRequest.state.errors.email">
            <el-input v-model="form.email" placeholder="E-mail" type="text" autocomplete="email"/>
          </el-form-item>

          <el-form-item prop="password" :error="formRequest.state.errors.password">
            <el-input v-model="form.password" placeholder="Пароль" type="password" autocomplete="password"/>
          </el-form-item>

          <el-form-item prop="confirmPassword">
            <el-input v-model="form.confirmPassword" placeholder="Повторите пароль" type="password" autocomplete="off"/>
          </el-form-item>

          <el-form-item prop="captcha" :error="formRequest.state.errors.captcha" class="captcha-wrapper">
            <vue-turnstile v-model="form.captcha" :site-key="turnstileSiteKey" theme="light" size="flexible" />
          </el-form-item>

          <el-form-item>
            <div class="text-center">
              <el-button type="primary" native-type="submit">Зарегистрироваться</el-button>
            </div>
          </el-form-item>

        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<style scoped>

.card {
  max-width: 400px;
  margin: auto;
}

.captcha-wrapper .el-form-item__content > div {
  width: 100%;
}
</style>
