<script setup lang="ts">
import { iMessageAttachment } from '@/types/common/models'
import MessageImage from '@/components/chat/MessageImage.vue'
import MessageFile from '@/components/chat/MessageFile.vue'
import { computed } from 'vue'

const props = defineProps<{
  attachment: iMessageAttachment,
  path: string
}>()

const emit = defineEmits(['load'])

const isPreview = computed(() => props.attachment.mime === 'image/jpeg' || props.attachment.mime === 'image/png')
const src = computed(() => `files/${props.path}/${props.attachment.filename}`)
</script>

<template>
  <message-image v-if="isPreview" :src="src" :title="attachment.title" @load="emit('load')" />
  <message-file v-else :src="src" :title="attachment.title" />
</template>
