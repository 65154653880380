<script setup lang="ts">

import ExchangeChat from '@/components/chat/ExchangeChat.vue'
import { reactive, watch } from 'vue'
import store from '@/store'
import { ElNotification } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const state = reactive<{
  currentExchangeId?: string
}>({
  currentExchangeId: undefined
})

// Наблюдаем за сообщениями по сделкам
watch(() => store.state.chatExchangeMessages.length, () => {
  const message = store.state.chatExchangeMessages[store.state.chatExchangeMessages.length - 1]
  if (message) {
    if (message.chatId === state.currentExchangeId) return

    const notification = ElNotification({
      type: 'success',
      title: 'Новое сообщение для сделки',
      message: message.message || 'Вложение',
      onClick: () => {
        state.currentExchangeId = message.chatId
        notification.close()
      }
    })
  }
})

// Наблюдаем за сообщениями в техподдержку от пользователя
watch(() => store.state.chatSupportMessages.length, () => {
  const message = store.state.chatSupportMessages[store.state.chatSupportMessages.length - 1]
  if (message) {
    if (route.name === 'support-chat' && route.params.chatId === message.chatId) return

    const notification = ElNotification({
      type: 'success',
      title: 'Новое сообщение',
      message: message.message || 'Вложение',
      onClick: () => {
        router.push({ name: 'tickets-chat', params: { chatId: message.chatId } })
        notification.close()
      }
    })
  }
})

// Наблюдаем за сообщениями в техподдержу от менеджера
watch(() => store.state.chatSupportUserMessages.length, () => {
  const message = store.state.chatSupportUserMessages[store.state.chatSupportUserMessages.length - 1]
  if (message) {
    if (route.name === 'support') return

    const notification = ElNotification({
      type: 'success',
      title: 'Новое сообщение',
      message: message.message || 'Вложение',
      onClick: () => {
        router.push({ name: 'support' })
        notification.close()
      }
    })
  }
})

</script>

<template>
  <exchange-chat :uuid="state.currentExchangeId" @close="state.currentExchangeId = undefined" />
</template>
