import { reactive } from 'vue'
import { iCurrentRates, iUserStateResponse } from '@/types/common/responses'
import {
  iChatMessageModel, iChatType,
  iCustomerQueue,
  iExchange, iSupportTicket,
  iTraderQueue,
  iUserModel
} from '@/types/common/models'
import cookieToken from '@/store/cookieToken'
import { socketSend } from '@/socket'
import { iClientCommands } from '@/types/common/socket/commands/client'
import { UnwrapNestedRefs } from '@vue/reactivity'

type iStoreState = Omit<iUserStateResponse, 'user'> & {
  userLoaded: boolean
  unseenTickets: iSupportTicket[] // Данные будут только у ролей с функцией тех поддержки - новые тикеты
  user: iUserModel | undefined
  rates: iCurrentRates
}

type iStore = {
  [C in keyof iClientCommands]: iClientCommands[C] extends undefined
    ? (this: iStore) => void
    : (this: iStore, payload: iClientCommands[C]) => void
} & {
  state: UnwrapNestedRefs<iStoreState>
  setMessageSeen: (this: iStore, chatType: iChatType, chatId: string | undefined, id: number) => void
  getChatMessages: (this: iStore, chatType: iChatType, chatId: string | undefined) => iChatMessageModel[]
}

const defaultStore: iStoreState = {
  userLoaded: false,
  user: undefined,
  accounts: [],
  traderQueue: [],
  chatSupportMessages: [],
  chatSupportUserMessages: [],
  chatExchangeMessages: [],
  supportTickets: [],
  unseenTickets: [],
  customerQueue: [],
  exchanges: [],
  rates: {
    UsdtToRub: '',
    RubToUsdt: ''
  },
  balance: 0,
  token: ''
}

const setItemByUUID = <T extends { uuid: string }> (items: T[], value: T, toStart = false) => {
  const index = items.findIndex(i => i.uuid === value.uuid)
  if (index >= 0) {
    items.splice(index, 1, value)
  } else {
    if (toStart) {
      items.unshift(value)
    } else {
      items.push(value)
    }
  }
}

// Перед добавлением методов необходимо добавить их описание в iClientCommands в виде { ...methodName: payloadType }

const store: iStore = {
  state: reactive({
    ...defaultStore
  }),
  /**
   * @deprecated Более не используется, кроме как в EventBus, смысл перенесен в login
   * @param payload
   */
  setUser (payload: iUserModel) {
    this.state.user = payload
  },
  getChatMessages (chatType, chatId) {
    // Забираем все сообщения пользователя в поддержку
    if (chatType === 'support' && chatId === undefined) {
      const result = this.state.chatSupportUserMessages
      this.state.chatSupportUserMessages = []
      return result
    }
    // Забираем сообщения соответствующего чата по его ID
    const prop = chatType === 'support' ? 'chatSupportMessages' : 'chatExchangeMessages'
    const result = this.state[prop].filter(msg => msg.chatId === chatId)
    this.state[prop] = this.state[prop].filter(msg => msg.chatId !== chatId)
    return result
  },
  setMessageSeen (chatType, chatId, id) {
    const param = chatType === 'exchange'
      ? 'chatExchangeMessages'
      : (chatId === undefined ? 'chatSupportUserMessages' : 'chatSupportMessages')

    const index = this.state[param].findIndex(c => c.id === id)
    if (index >= 0) {
      this.state[param].splice(index, 1)
    }
  },
  acceptTicket (payload) {
    const index = this.state.supportTickets.findIndex(t => t.id === payload.id)
    this.state.supportTickets.splice(index, 1, payload)
  },
  addSupportChatMessage (payload: iChatMessageModel) {
    this.state.chatSupportMessages.push(payload)
  },
  addExchangeChatMessage (payload: iChatMessageModel) {
    this.state.chatExchangeMessages.push(payload)
  },
  addSupportUserChatMessage (payload: iChatMessageModel) {
    this.state.chatSupportUserMessages.push(payload)
  },
  addTicket (payload: iSupportTicket) {
    this.state.supportTickets.unshift(payload)
    this.state.unseenTickets.push(payload)
  },
  login (data: iUserStateResponse) {
    Object.assign(this.state, data)
    cookieToken.set(data.token)
    socketSend<'auth'>({
      command: 'auth',
      payload: {
        token: data.token
      }
    })
    this.state.userLoaded = true
  },
  logout () {
    Object.assign(this.state, defaultStore)
    cookieToken.clear()
    socketSend<'logout'>({
      command: 'logout',
      payload: undefined
    })

    // Очищаем указатели
    this.state.accounts = []
    this.state.traderQueue = []
    this.state.customerQueue = []
    this.state.exchanges = []
    this.state.supportTickets = []
  },
  setExchange (payload: iExchange) {
    setItemByUUID<iExchange>(this.state.exchanges, payload, true)
  },
  setCustomerQueue (payload: iCustomerQueue) {
    setItemByUUID<iCustomerQueue>(this.state.customerQueue, payload)
  },
  setTraderQueue (payload: iTraderQueue) {
    setItemByUUID<iTraderQueue>(this.state.traderQueue, payload)
  },
  setBalance (payload: number) {
    this.state.balance = payload
  },
  setCurrentRates (payload: iCurrentRates) {
    this.state.rates = payload
  }
}

export default store
