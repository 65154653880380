<script setup lang="ts">
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import AuthorizationView from '@/components/AuthorizationView.vue'
import cookieToken from '@/store/cookieToken'
import MainMenu from '@/components/MainMenu.vue'
import UserInfo from '@/components/UserInfo.vue'
import NotificationListener from '@/components/chat/NotificationListener.vue'
import { ref, watch } from 'vue'
import { Menu as MenuIcon } from '@element-plus/icons-vue'

const route = useRoute()
const router = useRouter()

const menuOpened = ref(false)
watch(() => menuOpened.value, () => {
  if (menuOpened.value) {
    document.body.classList.add('scroll-disable')
  } else {
    document.body.classList.remove('scroll-disable')
  }
})
watch(() => router.currentRoute.value, () => {
  menuOpened.value = false
})

cookieToken.check()
</script>

<template>
  <template v-if="store.state.user">
    <el-header class="hidden-md-and-up header">
      <el-container class="header-container">
          <user-info />
        <el-button @click="menuOpened = !menuOpened" :type="menuOpened ? 'primary' : ''">
          <el-icon><menu-icon /></el-icon>
        </el-button>
      </el-container>
      <div v-if="menuOpened" class="floating-menu">
        <main-menu @close="menuOpened = false" />
      </div>
    </el-header>
    <el-container>
      <el-aside width="20%" class="hidden-sm-and-down">
        <user-info />
        <div>
          <el-scrollbar>
            <main-menu />
          </el-scrollbar>
        </div>
      </el-aside>
      <el-main width="80%" class="el-main__custom">
        <el-scrollbar>
          <router-view/>
        </el-scrollbar>
      </el-main>
      </el-container>
      <notification-listener />
  </template>
  <template v-else-if="['register-confirm', 'not-found', 'api-documentation'].includes(route.name)">
    <el-container>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </template>
  <template v-else>
    <authorization-view />

    <el-footer class="footer">
      <div class="text-center">
        <router-link :to="{name: 'api-documentation'}">API документация</router-link>
      </div>
    </el-footer>
  </template>

</template>

<style scoped>
.el-main__custom {
  padding: 0;
}
.footer {
  margin-top: 2em;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  position: sticky;
  width: 100%;
  height: 64px;
  top: 0;
  left: 0;
  z-index: 800;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.floating-menu {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: calc(100% - 64px);
  background-color: #fff;
  overflow-y: auto;
  z-index: 1000;
  padding-bottom: 1em;
  border-top: 1px solid lightgrey;
}

.el-drawer.rtl {
  min-width: 350px;
}

@media only screen and (max-width: 600px) {
  .footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    padding: 1em 20px;
  }
}
</style>
