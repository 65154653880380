<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { ElUpload, FormInstance, InputInstance, FormRules, UploadUserFile } from 'element-plus'
import { Paperclip } from '@element-plus/icons-vue'
import { iChatMessageModel } from '@/types/common/models'
import useFormRequest from '@/composables/useFormRequest'

type iChatForm = {
  message: string
  files: UploadUserFile[]
}

const uploadRef = ref<InstanceType<typeof ElUpload>>()
const formRequest = useFormRequest()
const formRef = ref<FormInstance>()
const inputRef = ref<InputInstance>()

const props = defineProps<{
  submit:(form: FormData) => Promise<iChatMessageModel>
}>()
const emit = defineEmits(['send'])

const form = reactive<iChatForm>({
  message: '',
  files: []
})

const rules = computed<FormRules<iChatForm>>(() => {
  return form.files.length === 0
    ? {
        message: [
          { type: 'string', message: 'Напишите сообщение', trigger: 'change', required: true }
        ]
      }
    : {}
})

const addAttachment = () => {
  // @todo Избавиться от этого костыля
  (document.querySelector('.chat-form input[type=file]') as HTMLInputElement).click()
}

const handleSubmit = async () => {
  formRef.value?.validate(valid => {
    if (valid) {
      formRequest.tryRequest(async () => {
        const formData = new FormData()
        formData.append('message', form.message.trim())

        form.files.forEach(file => {
          if (file.raw instanceof Blob) {
            formData.append('files', file.raw)
          }
        })

        const response = await props.submit(formData)

        formRef.value?.resetFields()
        emit('send', response)
      })
    }
  })
}

const handleInputEnter = (e: KeyboardEvent) => {
  if (e.shiftKey) {
    return
  }

  handleSubmit()
}

</script>

<template>
  <el-form
    ref="formRef"
    class="chat-form"
    :model="form"
    :rules="rules"
    @submit.prevent="handleSubmit"
    :disabled="formRequest.state.pending"
  >
    <el-form-item prop="files" style="margin-bottom: 0">
      <el-upload
        ref="uploadRef"
        v-model:file-list="form.files"
        style="width: 100%;"
        multiple
        :auto-upload="false"
      />
    </el-form-item>
    <div class="wrapper">
      <div class="column message">
        <el-form-item prop="message">
          <el-input
            ref="inputRef"
            v-model="form.message"
            :autosize="{ maxRows: 5 }"
            type="textarea"
            placeholder="Текст сообщения"
            @keyup.enter.prevent="handleInputEnter"
          />
        </el-form-item>
      </div>
      <div class="column controls">
        <el-tooltip content="Добавить вложение">
          <el-button type="success" @click="addAttachment"><el-icon><Paperclip /></el-icon></el-button>
        </el-tooltip>
        <el-button type="primary" @click="handleSubmit">Отправить</el-button>
      </div>
    </div>
  </el-form>
</template>

<style lang="scss">
.chat-form.el-form  {
  .el-upload.el-upload--text {
    display: none;
  }

  .wrapper {
    display: flex;
    align-items: flex-end;

    .column {
      padding: 10px 10px 0;
      box-sizing: border-box;

      &.message {
        flex: 1;
      }

      &.controls {
        flex: 0 0 auto; /* колонки, ширина которых зависит от содержимого */
        margin-bottom: 18px;
      }
    }

    .el-form-item {
      margin-bottom: 18px;
    }

    .el-textarea__inner {
      resize: none;
    }
  }
}
</style>
