<script setup lang="ts">
import ChatForm from '@/components/chat/ChatForm.vue'
import { getExchangeMessageList, sendExchangeMessage } from '@/store/actions/chat'
import { computed, reactive, watch } from 'vue'
import { iChatMessageModel, iExchangeModel } from '@/types/common/models'
import store from '@/store'
import useDataRequest from '@/composables/useDataRequest'
import MessageItem from '@/components/chat/MessageItem.vue'
import { ExchangeStatusColors, ExchangeStatusLabels } from '@/helpers/labels'
import ClipboardText from '@/components/shared/ClipboardText.vue'
import { formatUsdt } from '../../helpers/format'

const props = defineProps<{
  uuid: string|undefined
}>()

const emit = defineEmits(['close'])

const currentUser = computed(() => store.state.user)
const state = reactive<{
  exchange?: iExchangeModel
  messages: iChatMessageModel[]
  // С помощью него разрешаем делать скроллинг после загрузки картинок
  waitForImgLoad: boolean
}>({
  messages: [],
  waitForImgLoad: false
})

const requestState = useDataRequest({
  hideErrorMessages: true
})

const handleSubmit = async (form: FormData) => {
  if (!props.uuid) {
    return
  }

  return sendExchangeMessage(props.uuid, form)
}

const addMessage = (message: iChatMessageModel) => {
  state.messages.push(message)
}

const onImgLoad = () => {
  if (state.waitForImgLoad) {
    // Если картинки грузятся больше 2 секунд, делаем так, чтобы скроллинг не реагировал на их загрузку
    setTimeout(() => {
      state.waitForImgLoad = false
    }, 2000)
  }
}

// При добавлении сообщений скроллинг вниз
watch(() => state.messages.length, () => {
  state.waitForImgLoad = true
})

watch(() => [props.uuid], () => {
  if (props.uuid) {
    requestState.tryRequest(async () => {
      if (!props.uuid) {
        return
      }

      const { items, exchange } = await getExchangeMessageList(props.uuid)
      state.exchange = exchange
      state.messages = items
    })
  }
}, { immediate: true })

// Смотрим изменения сделки
const exchangeInStore = computed(() => store.state.exchanges.find(e => e.uuid === props.uuid))
watch(() => exchangeInStore.value, () => {
  if (exchangeInStore.value) {
    state.exchange = exchangeInStore.value
  }
})

// Забираем из store новые сообщения, в том числе когда они приходят
watch(() => store.state.chatExchangeMessages.length, () => {
  state.messages = [...state.messages, ...store.getChatMessages('exchange', props.uuid)]
    .filter((v, i, self) => i === self.indexOf(v))
}, { immediate: true })

watch(() => requestState.state.error, () => {
  emit('close')
})

</script>

<template>
  <el-drawer :model-value="props.uuid !== undefined" @close="emit('close')" title="Чат по сделке">

    <el-descriptions v-if="state.exchange" :column="1" style="margin-bottom: 32px">
      <el-descriptions-item label="UUID">
        <clipboard-text :text="state.exchange?.uuid" />
      </el-descriptions-item>
      <el-descriptions-item v-if="state.exchange?.traderId === currentUser?.id" label="Счёт">{{ state.exchange?.traderAccount?.name }}</el-descriptions-item>
      <el-descriptions-item v-if="state.exchange?.customerId === currentUser?.id" label="Реквизиты">{{ state.exchange?.traderAccount?.value }}</el-descriptions-item>
      <el-descriptions-item label="Сумма">{{ formatUsdt(state.exchange?.amount) }}</el-descriptions-item>
      <el-descriptions-item label="Статус">
        <el-tag disable-transitions :type="ExchangeStatusColors[state.exchange?.status]">{{ ExchangeStatusLabels[state.exchange?.status] }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item v-if="state?.exchange?.status === 'inProgress'" label="Осталось">
        <el-countdown class="countdown" :value="new Date(state.exchange?.expiresAt).getTime()" />
      </el-descriptions-item>
    </el-descriptions>

    <div class="chat-view">
      <div class="chat-message-list">
        <message-item
          v-for="message in state.messages"
          :key="message.id"
          chat-type="exchange"
          :message="message"
          @load="onImgLoad"
        />
      </div>
    </div>
    <template #footer>
      <chat-form :submit="handleSubmit" @send="addMessage" />
    </template>
  </el-drawer>
</template>

<style lang="scss" scoped>
.countdown, .countdown * {
  display: inline;
}
.chat-view {
  display: flex;
  flex-direction: column;

  .chat-message-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    min-height: 150px;
  }
}
</style>
