<script setup lang="ts">
import LoginForm from '@/components/LoginForm.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import RegistrationConfirmForm from '@/components/RegistrationConfirmForm.vue'
import { ref } from 'vue'

type AuthPageMode = 'login' | 'register'

const mode = ref<AuthPageMode>('login')
const email = ref<string>('')

const handleVerified = () => {
  mode.value = 'login'
  email.value = ''
}

</script>

<template>
  <template v-if="mode === 'register'">
    <registration-confirm-form v-if="email" :email="email" @verified="handleVerified" />
    <registration-form v-else @create="value => { email = value }" />

    <div class="text-center">
      <el-button @click="mode = 'login'">Вход</el-button>
    </div>
  </template>

  <template v-else>
    <login-form/>
    <div class="text-center">
      <el-button @click="mode = 'register'">Регистрация</el-button>
    </div>
  </template>
</template>
