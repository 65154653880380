<script setup lang="ts">
import { iChatMessageModel, iChatType } from '@/types/common/models'
import MessageAttachment from '@/components/chat/MessageAttachment.vue'
import store from '@/store'
import { computed } from 'vue'
import { dayjs } from 'element-plus'
import { setMessageSeen } from '@/store/actions/chat'

type iMessageType = 'in' | 'out'

const props = defineProps<{
  chatType: iChatType
  message: iChatMessageModel
}>()

const emit = defineEmits(['load'])

const path = computed(() => `${props.chatType}/${props.message.chatId}`)

const created = dayjs(props.message.createdAt).format('DD.MM.YYYY HH:mm')

const messageType: iMessageType = props.message.userId === store.state.user?.id ? 'out' : 'in'

if (!props.message.isViewed) {
  // Отмечаем сообщение как прочитанное, если писали его не мы
  if (store.state.user && props.message.userId !== store.state.user.id) {
    setMessageSeen(props.chatType, props.message.chatId, props.message.id)
  }
}
</script>

<template>
  <div class="chat-message" :class="['message-' + messageType]">
    <div v-if="props.message.attachments">
      <div v-if="props.message.userId !== store.state.user?.id" class="message-username">{{ props.message.user.name }}</div>
      <message-attachment
        v-for="attachment in props.message.attachments"
        :key="attachment.filename"
        :path="path"
        :attachment="attachment"
        @load="emit('load')"
      />
    </div>

    <div class="message-text">{{ props.message.message }}</div>
    <div class="message-timestamp">{{ created }}</div>
  </div>
</template>

<style lang="scss">
.chat-message {
  max-width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  white-space: pre;
}

.message-username {
  font-weight: bold;
  font-size: small;
}

.message-text {
  //noinspection CssInvalidPropertyValue
  text-wrap: wrap;
  text-align: left;
}

.message-timestamp {
  font-size: small;
  font-weight: lighter;
  margin-top: 1rem;
}

.message-out {
  background-color: #d1e7dd;
  align-self: flex-end;
  text-align: right;

  .message-timestamp {
    align-self: flex-start;
    text-align: left;
  }
}

.message-in {
  margin-right: auto;
  background-color: #f8d7da;
  align-self: flex-start;
  text-align: left;

  .message-timestamp {
    align-self: flex-end;
    text-align: right;
  }
}
</style>
