<template>
  <div class="user-info">
    {{ name }}
    <div v-if="can('USDT_ACCOUNT')" class="balance">{{ balanceFormatted }}</div>
  </div>
</template>

<script setup lang="ts">

import store from '@/store'
import { computed } from 'vue'
import { can } from '@/helpers/utils'
import { formatUsdt } from '@/helpers/format'

const balanceFormatted = computed(() => formatUsdt(store.state.balance))
const name = computed(() => store.state.user?.name)

</script>

<style lang="scss">
.user-info {
  padding: 15px;

  .balance {
    font-size: .7em;
    font-weight: bolder;
    text-align: right;
  }
}
</style>
