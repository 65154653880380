import { iConfirmRegisterForm, iLoginForm, iRegisterForm } from '@/types/common/requests'
import { iUserStateResponse } from '@/types/common/responses'
import * as api from '@/api/auth'

export const login = async (data: iLoginForm): Promise<iUserStateResponse> => {
  return (await api.login(data)).data
}
export const getMyUser = async (): Promise<iUserStateResponse> => {
  return (await api.getMyUser()).data
}
export const register = async (data: iRegisterForm): Promise<void> => {
  await api.register(data)
}
export const confirmRegister = async (data: iConfirmRegisterForm) => {
  return await api.confirmRegister(data)
}
