<script setup lang="ts">
import ForbiddenView from '@/components/ForbiddenView.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'

const props = defineProps<{
  title?: string
  code?: number
  className?: string
}>()
</script>

<template>
  <forbidden-view v-if="props.code === 403" />
  <not-found-page v-else-if="props.code === 404" />
  <template v-else>
    <el-card :class="className">
      <template v-if="props.title" #header>
        <div class="card-header">
          <span>{{ props.title}} </span>

          <div class="card-header__after">
            <slot name="after" />
          </div>
        </div>
      </template>

      <slot></slot>
    </el-card>
  </template>
</template>
