import instance from '@/api/instance'
import { AxiosPromise } from 'axios'
import { iConfirmRegisterForm, iLoginForm, iRegisterForm } from '@/types/common/requests'
import { iUserStateResponse } from '@/types/common/responses'

export const login = (data: iLoginForm): AxiosPromise<iUserStateResponse> => instance.post('auth/login', data)
export const getMyUser = (): AxiosPromise<iUserStateResponse> => instance.get('user/me')
export const register = (data: iRegisterForm): AxiosPromise<void> => instance.post('auth/register', data)
export const confirmRegister = (data: iConfirmRegisterForm): AxiosPromise<void> => instance.post('auth/register-confirm', data)
export const removeToken = () => { delete instance.defaults.headers.common['X-Access-Token'] }
export const setToken = (token: string) => { instance.defaults.headers.common['X-Access-Token'] = token }
