import instance from '@/api/instance'
import { iCreateSupportTicketForm } from '@/types/common/requests'
import { AxiosPromise } from 'axios'
import { iChatMessageModel, iSupportTicket, iSupportTicketModel } from '@/types/common/models'
import { iGetExchangeMessagesResponse, iGetSupportTicketMessagesResponse } from '@/types/common/responses'

export const getSupportMessageList = (chatId?: string): AxiosPromise<iGetSupportTicketMessagesResponse> =>
  instance.get(chatId === undefined ? '/chat/support' : '/chat/support/' + chatId)

export const createSupportTicket = (data: iCreateSupportTicketForm): AxiosPromise<iSupportTicket> =>
  instance.post('/chat/support', data)

export const sendSupportMessage = (chatId: string | undefined, formData: FormData): AxiosPromise<iChatMessageModel> =>
  instance.post<iChatMessageModel>(chatId === undefined ? '/chat/support' : `/chat/support/${chatId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

export const getExchangeMessageList = (chatId: string): AxiosPromise<iGetExchangeMessagesResponse> =>
  instance.get('/chat/exchange/' + chatId)

export const sendExchangeMessage = (exchangeId: string, formData: FormData) =>
  instance.post<iChatMessageModel>(`/chat/exchange/${exchangeId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

export const acceptTicket = (id: number): AxiosPromise<iSupportTicketModel> =>
  instance.post('/chat/tickets/accept', { id })
export const closeTicket = (id: number): AxiosPromise<iSupportTicketModel> =>
  instance.post('/chat/tickets/close', { id })
