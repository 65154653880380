<script setup lang="ts">
import store from '@/store'
import { User, Money, ArrowUp, ArrowDown, ChatRound, Setting, UserFilled, MagicStick, DataBoard } from '@element-plus/icons-vue'
import { can } from '@/helpers/utils'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const handleLogout = () => {
  store.logout()
  router.replace('/')
}

const emit = defineEmits(['close'])

const supportMessageCount = computed(() => store.state.chatSupportUserMessages.length)
const traderMessageCount = computed(() => store.state.exchanges
  .filter(e => e.traderId === store.state.user?.id)
  .reduce((carry, exchange) => {
    return carry + store.state.chatExchangeMessages.filter(c => c.chatId === exchange.uuid).length
  }, 0)
)
const customerMessageCount = computed(() => store.state.exchanges
  .filter(e => e.customerId === store.state.user?.id)
  .reduce((carry, exchange) => {
    return carry + store.state.chatExchangeMessages.filter(c => c.chatId === exchange.uuid).length
  }, 0)
)
const ticketsMessageCount = computed(() => store.state.chatSupportMessages.length)

const handleClick = () => {
  emit('close')
}

</script>

<template>
  <el-menu router style="display: flex; flex-direction: column; justify-content: space-between; flex: 1;">
    <div>
      <el-menu-item index="/" @click="handleClick"><el-icon><user /></el-icon> Профиль</el-menu-item>
      <el-menu-item v-if="can('DISPOSER_ONLY')" index="/disposer" @click="handleClick"><el-icon><data-board /></el-icon> Панель управляющего</el-menu-item>
      <el-menu-item v-if="can('VIEW_ACCOUNT_LIST')" index="/account" @click="handleClick"><el-icon><money /></el-icon> Счета</el-menu-item>
      <el-menu-item v-if="can('VIEW_OFFER_LIST')" index="/trader" @click="handleClick"><el-icon><arrow-up /></el-icon> Продажа <el-tag v-if="traderMessageCount" type="warning" style="margin-left: 8px">{{ traderMessageCount }}</el-tag></el-menu-item>
      <el-menu-item v-if="can('VIEW_OFFER_LIST')" index="/customer" @click="handleClick"><el-icon><arrow-down /></el-icon> Покупка <el-tag v-if="customerMessageCount" type="warning" style="margin-left: 8px">{{ customerMessageCount }}</el-tag></el-menu-item>
      <el-menu-item v-if="can('VIEW_CHAT')" index="/support" @click="handleClick"><el-icon><chat-round /></el-icon> Техподдержка <el-tag v-if="supportMessageCount" type="warning" style="margin-left: 8px">{{ supportMessageCount }}</el-tag></el-menu-item>
      <el-menu-item v-if="can('VIEW_TICKETS')" index="/tickets" @click="handleClick"><el-icon><chat-round /></el-icon> Тикеты <el-tag v-if="ticketsMessageCount" type="warning" style="margin-left: 8px">{{ ticketsMessageCount }}</el-tag></el-menu-item>
      <el-menu-item v-if="can('VIEW_APPLICATION_SETTINGS')" index="/settings" @click="handleClick"><el-icon><setting /></el-icon> Настройки</el-menu-item>
      <el-menu-item v-if="can('VIEW_USER_LIST')" index="/users" @click="handleClick"><el-icon><user-filled /></el-icon> Пользователи</el-menu-item>
      <el-menu-item v-if="can('VIEW_API_TOKEN')" index="/api" @click="handleClick"><el-icon><magic-stick /></el-icon> API</el-menu-item>
    </div>

    <div class="text-center">
      <el-button @click="handleLogout">Выйти</el-button>
    </div>
  </el-menu>
</template>
