import { ElLoading } from 'element-plus'
import store from '@/store/index'
import { getMyUser } from '@/store/actions/auth'
import { removeToken, setToken } from '@/api/auth'

export default {
  set (token: string) {
    setToken(token)
    document.cookie = `exchange_token=${token};path=/;`
  },
  clear () {
    removeToken()
    document.cookie = 'exchange_token=;path=/;'
  },
  get () {
    return (new Map<string, string>(
      document.cookie.split(';').map<Array<string>>(i => i.trim().split('=')) as [string, string][]
    )).get('exchange_token')
  },
  check () {
    const token = this.get()
    if (token) {
      setToken(token)
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка'
      })
      getMyUser().then(response => {
        store.login(response)
      }).catch(() => {
        store.logout()
      }).finally(() => {
        loading.close()
      })
    }
  }
}
