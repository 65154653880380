<script setup lang="ts">

import { reactive, ref } from 'vue'
import { ElNotification, FormInstance, FormRules } from 'element-plus'
import useFormRequest from '@/composables/useFormRequest'
import { confirmRegister } from '@/store/actions/auth'
import { iConfirmRegisterForm } from '@/types/common/requests'

interface iFormState {
  code: string
}

const props = defineProps<{
  email: string
}>()

const formRef = ref<FormInstance>()
const formRequest = useFormRequest<iConfirmRegisterForm>()
const emit = defineEmits(['verified'])

const form = reactive<iFormState>({
  code: ''
})

const rules = reactive<FormRules<iFormState>>({
  code: [
    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
  ]
})

const handleSubmit = async () => {
  await formRef.value?.validate(valid => {
    if (valid) {
      formRequest.tryRequest(async () => {
        await confirmRegister({
          code: form.code,
          email: props.email
        })
        ElNotification({ message: 'Email подтвержден, выполните вход', type: 'success' })
        emit('verified')
      })
    }
  })
}
</script>

<template>
  <el-container>
    <el-main>
      <el-card class="card">
        <template #header>Регистрация</template>
          <el-form
            :model="form"
            :rules="rules"
            ref="formRef"
            status-icon
            @submit.prevent="handleSubmit"
            v-loading="formRequest.state.pending"
          >
            <p>
              Регистрация почти завершена.
              На Вашу почту был отправлен код подтверждения, укажите его.
            </p>

            <el-form-item prop="code" :error="formRequest.state.errors.code">
              <el-input v-model="form.code" placeholder="Код из письма" type="text" />
            </el-form-item>

            <el-form-item>
              <div class="text-center">
                <el-button type="primary" native-type="submit">Подтвердить</el-button>
              </div>
            </el-form-item>
          </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<style scoped>

.card {
  width: 400px;
  margin: auto;
}

</style>
