import {
  iCustomerQueueStatus,
  iExchangeStatus,
  iSupportTicketStatus,
  iTraderQueueStatus,
  iUserAccountType,
  iUserRole
} from '@/types/common/models'

type iColor = 'primary' | 'success' | 'info' | 'warning' | string

export const TraderQueueStatusLabels: Record<iTraderQueueStatus, string> = {
  new: 'Новый',
  matched: 'Сошелся',
  done: 'Завершен'
}
export const CustomerQueueStatusLabels: Record<iCustomerQueueStatus, string> = {
  new: 'Новый',
  matched: 'Сошелся',
  done: 'Завершен'
}
export const ExchangeStatusLabels: Record<iExchangeStatus, string> = {
  inProgress: 'В процессе',
  aborted: 'Отменен',
  completed: 'Завершен'
}

export const SupportTicketStatusLabels: Record<iSupportTicketStatus, string> = {
  new: 'Новый',
  active: 'В работе',
  closed: 'Закрыт'
}

export const TraderQueueStatusColors: Record<iTraderQueueStatus, iColor> = {
  new: 'primary',
  matched: 'warning',
  done: 'success'
}
export const CustomerQueueStatusColors: Record<iCustomerQueueStatus, iColor> = {
  new: 'primary',
  matched: 'warning',
  done: 'success'
}
export const ExchangeStatusColors: Record<iExchangeStatus, iColor> = {
  inProgress: 'primary',
  aborted: 'info',
  completed: 'success'
}

export const SupportTicketStatusColors: Record<iSupportTicketStatus, iColor> = {
  new: 'primary',
  active: 'warning',
  closed: 'info'
}

export const UserRoleLabels: Record<iUserRole, string> = {
  admin: 'Администратор',
  manager: 'Менеджер',
  partner: 'Партнер',
  client: 'Клиент',
  disposer: 'Управляющий'
}

export const UserAccountTypeLabels: Record<iUserAccountType, string> = {
  bank: 'Банковский счёт',
  'sbp-phone': 'СБП: телефон',
  usdt: 'USDT счёт'
}
