import axios, { CreateAxiosDefaults } from 'axios'

/*
  Интерфейс конфига AXIOS не понимает rejectUnauthorized, поправим это тут,
  он нам нужен, чтобы избегать кривых SSL сертификатов, если они будут
 */
interface AxiosConfig extends CreateAxiosDefaults {
  rejectUnauthorized: boolean
}

export const config: AxiosConfig = {
  baseURL: process.env.VUE_APP_URI_API_HTTP,
  rejectUnauthorized: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}

export default axios.create(config)
