<script setup lang="ts">

import { DocumentCopy } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

const props = defineProps<{
  text?: string
}>()

const handleCopyToClipboard = () => {
  if (props.text) {
    navigator.clipboard.writeText(props.text)
    ElMessage.success({ type: 'success', message: 'Текст скопирован в буфер обмена' })
  }
}

</script>

<template>
  <el-text>
    {{ props.text }}

    <el-button type="text" @click="handleCopyToClipboard" style="margin-left: 12px">
      <el-icon><DocumentCopy /></el-icon>
    </el-button>
  </el-text>
</template>
