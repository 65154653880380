<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import instance from '@/api/instance'

const props = defineProps<{
  title: string
  src: string
}>()

const emit = defineEmits(['load'])

const imageUrl = ref<string | null>(null)

onMounted(async () => {
  try {
    const response = await instance.get(props.src, {
      responseType: 'blob' // Чтобы получить изображение в виде Blob
    })

    const imageBlob = response.data
    imageUrl.value = URL.createObjectURL(imageBlob)
  } catch (error) {
    console.error('Ошибка при загрузке изображения', error)
  }
})

onUnmounted(() => {
  if (imageUrl.value) {
    URL.revokeObjectURL(imageUrl.value)
  }
})
</script>

<template>
  <div class="chat-image-thumb">
    <el-skeleton :loading="imageUrl === null" animated>
      <template #template>
        <el-skeleton-item variant="image" style="width: 100px; height: 100px" />
      </template>
      <template #default>
        <el-image
          :src="imageUrl"
          :preview-src-list="[imageUrl]"
          :initial-index="0"
          :max-scale="3"
          :min-scale="0.5"
          hide-on-click-modal
          @load="emit('load')"
        />
      </template>
    </el-skeleton>
  </div>
</template>

<style lang="scss">
.chat-image-thumb {
  display: inline-block;
  margin: 5px;
  max-height: 150px;
  min-height: 20px;
  text-align: center;
  vertical-align: middle;
  img {
    max-height: 150px;
    max-width: 200px;
    min-height: 50px;
  }
}
</style>
