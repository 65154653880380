<script setup lang="ts">
import { ref } from 'vue'
import instance from '@/api/instance'
import { Document } from '@element-plus/icons-vue'

const props = defineProps<{
  title: string
  src: string
}>()

const loading = ref<boolean>(false)

const downloadFile = async () => {
  loading.value = true

  try {
    const response = await instance.get(props.src, {
      responseType: 'blob'
    })

    const url = URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = url
    link.download = props.title
    link.click()
    URL.revokeObjectURL(url)
    loading.value = false
  } catch (error) {
    console.error('Ошибка загрузки файла:', error)
  }
}
</script>

<template>
  <el-link
    v-loading="loading"
    href="#"
    type="primary"
    @click.prevent="downloadFile"
  ><el-icon><Document /></el-icon>&nbsp;{{ props.title }}</el-link>
</template>
