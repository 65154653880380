import { iCreateSupportTicketForm } from '@/types/common/requests'
import { iChatMessageModel, iChatType, iSupportTicket } from '@/types/common/models'
import * as api from '@/api/chat'
import { socketSend } from '@/socket'
import store from '@/store'
import { iGetExchangeMessagesResponse, iGetSupportTicketMessagesResponse } from '@/types/common/responses'

export const createSupportTicket = async (data: iCreateSupportTicketForm): Promise<iSupportTicket> => {
  return (await api.createSupportTicket(data)).data
}

export const acceptTicket = async (id: number): Promise<iSupportTicket> => {
  return (await api.acceptTicket(id)).data
}
export const closeTicket = async (id: number): Promise<iSupportTicket> => {
  return (await api.closeTicket(id)).data
}

export const getSupportMessageList = async (chatId?: string): Promise<iGetSupportTicketMessagesResponse> => {
  return (await api.getSupportMessageList(chatId)).data
}
export const sendSupportMessage = async (chatId: string | undefined, formData: FormData): Promise<iChatMessageModel> => {
  return (await api.sendSupportMessage(chatId, formData)).data
}

export const getExchangeMessageList = async (chatId: string): Promise<iGetExchangeMessagesResponse> => {
  return (await api.getExchangeMessageList(chatId)).data
}
export const sendExchangeMessage = async (chatId: string, formData: FormData): Promise<iChatMessageModel> => {
  return (await api.sendExchangeMessage(chatId, formData)).data
}

export const setMessageSeen = async (chatType: iChatType, chatId: string | undefined, id: number) => {
  store.setMessageSeen(chatType, chatId, id)
  socketSend({
    command: 'setIsViewed',
    payload: { id, chatType }
  })
}
