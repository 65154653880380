// Инициируется при авторизации

import {
  iClientCommandKeys,
  iClientCommandMessage,
  iServerCommandKeys,
  iServerCommandMessage
} from '@/types/common/socket'
import { iSocketCommand } from '@/types/socket'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { h } from 'vue'
import { MessageHandler } from 'element-plus/es/components/message/src/message'

const MAX_RECONNECT_ATTEMPTS = 5
let reconnectAttempts = 0
let ws: WebSocket | null = null
let msg: MessageHandler | null = null

const retryConnect = () => {
  reconnectAttempts = 0
  if (msg) {
    msg.close()
  }
  connect()
}

const connect = () => {
  ws = new WebSocket(process.env.VUE_APP_URI_WEB_SOCKET)

  ws.onopen = () => {
    reconnectAttempts = 0
    if (store.state.token) {
      // При подключении авторизуемся заново, на случай сбоя соединения
      socketSend<'auth'>({
        command: 'auth',
        payload: {
          token: store.state.token
        }
      })
    }
    console.log('Connected to WebSocket server')
  }

  ws.onmessage = (ev) => {
    const data: iClientCommandMessage<iClientCommandKeys> = JSON.parse(ev.data)

    const command = store[data.command]
    if (command !== undefined) {
      (command as iSocketCommand<unknown>).call(store, data.payload)
    } else {
      throw new Error(`Неизвестное действие: ${data.command}`)
    }
  }

  ws.onclose = () => {
    if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
      const timeout = Math.pow(2, reconnectAttempts) * 1000 // Экспоненциальная задержка
      console.log(`WebSocket connection closed. Attempting to reconnect in ${timeout / 1000} seconds...`)
      setTimeout(() => {
        reconnectAttempts++
        connect()
      }, timeout)
    } else {
      msg = ElMessage({
        type: 'warning',
        duration: 0,
        message: h('p', { style: 'line-height: 1; font-size: 14px' }, [
          h('span', null, 'Соединение с сервером потеряно. '),
          h('a', { style: 'color: teal', onClick: retryConnect, href: '#' }, 'повторить')
        ])
      })

      console.error('Max reconnect attempts reached. Could not reconnect to WebSocket server.')
    }
  }

  ws.onerror = (err) => {
    console.error('WebSocket encountered an error:', err)
    if (ws) { // Для устранения ошибки TypeScript
      ws.close()
    }
  }
}

connect()

export const socketSend = <T extends iServerCommandKeys>(message: iServerCommandMessage<T>) => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify(message))
    return true
  }
  return false
}
